import axios from "axios";
import { contractAddress, network } from "config";

export async function getTokenSwap(getFunction: string): Promise<any> {
  try {
    const { data } = await axios.post(
      `${network.gatewayAddress}/vm-values/query`,
      {
        scAddress: contractAddress,
        funcName: getFunction,
        args: []
      }
    );
    return { data: data, success: data !== undefined, message: "Error" };
  } catch (err) {
    return { success: false, message: "Error" };
  }
}
