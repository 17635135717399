import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { dAppName } from "config";
import { ReactComponent as ElrondLogo } from "./../../../assets/img/elrond.svg";
import { routeNames } from "routes";

const Navbar = () => {
  const { address, loggedIn } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push("/");
  };

  return (
    <BsNavbar className="bg-gray-700 border-0 px-4 py-3">
      <div className="container-fluid">
        <Nav className="mr-auto">
          <NavItem>
            {loggedIn ? (
            <div className="flex">
              <button
                onClick={logOut}
                className="btn btn-light text-dark border-0"
              >
                Logout
              </button>
              <div className="max-w-xs pt-2 pl-4 truncate font-bold text-gray-300">
                {address}
              </div>
            </div>
          ) : (
            <Link
              to={routeNames.unlock}
              style={{ textDecoration: "none" }}
              className="btn btn-light text-dark border-0"
            >
              Login
            </Link>
          )}
          </NavItem>
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;

