import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { contractAddress, network, dAppName } from "config";
import { routeNames } from "routes";
import { getTokenSwap } from "helpers/asyncRequests";
import logo from "assets/img/logo-war-token.png";
import { base64ToHexFunc } from "helpers/function";
import { MdSwapVert } from "react-icons/md";

import useNewTransaction from "pages/Transaction/useNewTransaction";
import * as Dapp from "@elrondnetwork/dapp";

export interface RawTransactionType {
  value: string;
  receiver: string;
  gasPrice?: number;
  gasLimit?: number;
  data?: string;
  chainID?: string;
  version?: number;
  nonce?: number;
}

const Home = () => {
  const { account : {balance}, address, loggedIn } = Dapp.useContext();

  const [swap, setSwap] = useState('0');
  const [totalSold, setTotalSold] = useState(0);
  const [totalLeft, setTotalLeft] = useState(0);

  useEffect(() => {
    fetchSwapped();
    fetchStorage();
  }, []);

  const fetchSwapped = async () => {
    console.log("try get Total Sold");
    const res = await getTokenSwap("getSwapped");
    if (res.success) {
      setTotalSold(
        parseInt(base64ToHexFunc(res.data.data.data.returnData), 16) /
          Math.pow(10, 18)
      );
    }
  };
  const fetchStorage = async () => {
    console.log("try get Total Left");
    const res = await getTokenSwap("getStorage");
    if (res.success) {
      setTotalLeft(
        parseInt(base64ToHexFunc(res.data.data.data.returnData), 16) /
          Math.pow(10, 18)
      );
    }
  };

  const newTransaction = useNewTransaction();
  const sendTransaction = Dapp.useSendTransaction();

  const sendTransactionSwap = async () => {
    const transaction: RawTransactionType = {
      receiver: contractAddress,
      data: "swap",
      value: "" + Number(swap),
      gasLimit: 20000000,
    };
    sendTransaction({
      transaction: newTransaction(transaction),
      callbackRoute: routeNames.transaction,
    });
  };

  const abbreviateNumber = (number: number) => {
    const SI_SYMBOL = [
      '',
      'k',
      'Million',
      'Billion',
      'Trillion',
      'Quadrillion',
      'Quintillion',
      'Sextillion',
      'Septillion'
    ];
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;
    if (tier == 0) return number;
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    return scaled.toFixed(1) + ' ' + suffix;
  };
  const nf = new Intl.NumberFormat();

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-8 my-auto px-10 md:px-[12%] w-100 h-100 bg-gray-600 grid-flow-row-dense '>
      <form className='bg-gray-700 shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4'>
        <div className='py-2'>
          <img src={logo} className='mx-auto h-40' />
        </div>
        <div className='py-2'>
          {balance !== '...' && (
            <span className='ml-2 text-sm text-light'>
              Your EGLD: {parseInt(balance) * Math.pow(10, -18)}
            </span>
          )}
          <input
            className='shadow-md appearance-none rounded-xl w-full py-2 px-3 bg-gray-600 text-gray-300 border-none leading-tight border-gray-500 focus:outline-none focus:shadow-outline'
            type='number'
            min={0}
            step={0.001}
            placeholder='EGLD'
            value={swap}
            onChange={(e) => setSwap(e.target.value)}
          />
        </div>
        <div className='py-4 flex justify-center '>
          {loggedIn ? (
            <button
              className='btn btn-primary w-100'
              onClick={sendTransactionSwap}
              type='button'
            >
              Swap
            </button>
          ) : (
            <Link
              to={routeNames.unlock}
              style={{ textDecoration: 'none' }}
              className='btn btn-primary w-100 text-light'
            >
              Swap
            </Link>
          )}
        </div>
        <div className='bg-gray-600 my-2 px-5 py-2 shadow-md rounded-2xl'>
          <p className='text-gray-300 text-lg m-0'>Total Sold :</p>
          <p className='text-white text-lg'>
            {abbreviateNumber(Number(totalSold))}
          </p>
          <p className='text-gray-300 text-lg m-0'>WAR Token Left :</p>
          <p className='text-white text-lg m-0'>
            {abbreviateNumber(Number(totalLeft))}
          </p>
        </div>
      </form>
      <div className='bg-gray-700 shadow-md rounded-xl  pt-6 pb-8 mb-4'>
        <div className='my-2 px-8'>
          <p className='text-gray-300 text-lg'>
            Swap your<span className='font-bold'> EGLD </span>for
            <span className='font-bold inline-block'>&nbsp;WAR Token </span>
          </p>
        </div>
        <div className='border ' />
        <div className='my-4 text-center px-8'>
          <p className='text-gray-300 text-2xl -mb-1'>
            {nf.format(Number(swap))} EGLD
          </p>
          <MdSwapVert size={40} color='white' className='mx-auto py-2' />
          <p className='text-gray-300 text-2xl'>
            {nf.format(Number(swap) * 5000000000000)} WAR Token
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
